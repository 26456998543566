import { color } from 'chart.js/helpers';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { additionalPackages } from '../components/packages/packageData';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generatePDF = (formData, displayFormats, callback) => {
	return new Promise((resolve, reject) => {
		console.log('Inside generatePDF:', displayFormats);
		console.log('FormData: ', formData);
		console.log('Type:', typeof displayFormats);
		console.log('Is Array:', Array.isArray(displayFormats));

		const rammeavtale =
			formData.rammeavtale === true ? [`Kunde har rammeavtale`] : [];
		const bestillingType = formData.typeOfOrder || 'Default Value';
		const ATE = formData.clientAteNumber
			? `ATE: ${formData.clientAteNumber}`
			: '';

		// Korrektur Date picker
		const formatDate = (dateString) => {
			console.log('Date String:', dateString);
			const date = new Date(dateString);
			return `${date.getDate()}/${
				date.getMonth() + 1
			}/${date.getFullYear()}`;
		};

		const contentArray = [
			{
				text: 'SELGER',
				fontSize: 18,
				color: '#005379',
				margin: [0, 80, 0, 0],
			},
			`Navn: ${formData.firstName} ${formData.lastName}`,
			`Epost: ${formData.email}`,

			{
				text: 'KUNDE',
				fontSize: 18,
				color: '#005379',
				margin: [0, 20, 0, 0],
			},

			`Kunde: ${formData.clientName}`,
			`Handlekurv: ${formData.shoppingCart}`,
			`Ordrenummer ${
				Array.isArray(formData.orderNumber)
					? formData.orderNumber.join(', ')
					: formData.orderNumber
			}`,
			`${ATE}`,
			...rammeavtale,

			{
				text: `${bestillingType} bestilling`,
				fontSize: 22,
				color: '#005379',
				margin: [0, 20, 0, 0],
			},
		];

		if (formData.landingpages) {
			const landingPagesFormatted = formData.landingpages.join('\n');
			const landingpagesArray = [
				{
					text: 'Landingssider',
					fontSize: 18,
					color: '#005379',
					margin: [0, 20, 0, 0],
				},

				`${landingPagesFormatted}`,
			];

			contentArray.push(...landingpagesArray);
		}

		if (formData.innholdsproduksjon) {
			const innholdsproduksjonArray = [
				{
					text: 'Innholdsproduksjon',
					fontSize: 18,
					color: '#005379',
					margin: [0, 20, 0, 0],
				},
			];

			// Add the selected content package
			innholdsproduksjonArray.push(
				`Pakke: ${
					formData.selectedContentPackage || 'Ingen pakke valgt'
				}\n`
			);

			// Handle the additional services (Tilvalg)
			if (
				formData.selectedAdditionalServices &&
				formData.selectedAdditionalServices.length > 0
			) {
				innholdsproduksjonArray.push('Tilvalg:');

				// Map selected services to their names
				const selectedServiceNames =
					formData.selectedAdditionalServices.map(
						(service) => service.name // Directly access the name from the object
					);

				// Add the service names to the PDF content
				selectedServiceNames.forEach((tilvalg) => {
					innholdsproduksjonArray.push(`- ${tilvalg}`);
				});
			}

			// If "Annet eller tillegg" option is selected
			if (formData.innholdAnnet) {
				innholdsproduksjonArray.push(`\nAnnet / Tillegg:`);
			}

			if (formData.innholdOther) {
				innholdsproduksjonArray.push(`${formData.innholdOther}`);
			}

			// Priser og visninger
			innholdsproduksjonArray.push(`\nPris of visninger: \n`);
			innholdsproduksjonArray.push(
				`Dato fra: ${formatDate(formData.innholdContentDateFrom)}\n`
			);
			if (formData.innholdContentDateTo) {
				innholdsproduksjonArray.push(
					`Dato til: ${formatDate(formData.innholdContentDateTo)}\n`
				);
			}
			if (formData.innholdContentNumShowing) {
				innholdsproduksjonArray.push(
					`Antall visninger: ${formData.innholdContentNumShowing}\n`
				);
			}
			if (formData.innholdContentMediaspend) {
				innholdsproduksjonArray.push(
					`Total mediaspend: ${formData.innholdContentMediaspend}\n`
				);
			}
			if (formData.innholdContentPublishing) {
				innholdsproduksjonArray.push(
					`Publiseringssted: ${formData.innholdContentPublishing}\n`
				);
			}

			innholdsproduksjonArray.push(`\n Antatt plassing av annonser: \n`);

			// Antatt plassing av annonser
			if (formData.contentpremium) {
				innholdsproduksjonArray.push(`Content Premium\n`);
			}
			if (formData.contentstandard) {
				innholdsproduksjonArray.push(`Content Standard\n`);
			}
			if (formData.premiumnorge) {
				innholdsproduksjonArray.push(
					`Premium Norge (Obs! Gule annonser)\n`
				);
			}
			if (formData.native) {
				innholdsproduksjonArray.push(`Native (OBS! gule annonser)\n`);
			}
			if (formData.annet) {
				innholdsproduksjonArray.push(
					`Annet (Skriv i fritekstfeltet under)\n`
				);
			}
			if (formData.placementothertext) {
				innholdsproduksjonArray.push(
					`${formData.placementothertext}\n`
				);
			}

			innholdsproduksjonArray.push(`\n Annen informasjon: \n`);
			innholdsproduksjonArray.push(
				`Rosenummer: ${formData.contentrosenummer}\n`
			);
			if (formData.contactinfocustomer) {
				innholdsproduksjonArray.push(
					`Kontaktinfo til kunde: ${formData.contactinfocustomer}\n`
				);
			}
			innholdsproduksjonArray.push(
				`Antatt lokasjon: ${formData.contentlocation}\n`
			);

			contentArray.push(...innholdsproduksjonArray);
		}

		// Eksterna kanaler
		if (formData.eksterneKanaler) {
			const eksterneArray = [
				{
					text: 'Eksterne kanaler',
					fontSize: 18,
					color: '#005379',
					margin: [0, 20, 0, 0],
				},
			];

			if (formData.externalMalsetning) {
				eksterneArray.push(
					`Målsetning: ${formData.externalMalsetning}\n`
				);
			}
			if (formData.externalMalretting) {
				eksterneArray.push(
					`Målretting: ${formData.externalMalretting}\n`
				);
			}
			if (formData.externalGeo) {
				eksterneArray.push(`Geo: ${formData.externalGeo}\n`);
			}

			// Facebook
			if (formData.externalFacebook) {
				const facebookArray = [
					{
						text: '\nFacebook\n',
						fontSize: 16,
						color: '#000000',
					},
				];

				if (formData.FacebookBildeannonse) {
					facebookArray.push(`Bildeannonse\n`);
				}
				if (formData.FacebookVideoannonse) {
					facebookArray.push(`Videoannonse\n`);
				}
				if (formData.FacebookKarusell) {
					facebookArray.push(`Karusell\n`);
				}
				if (formData.FacebookLysbildeframvisning) {
					facebookArray.push(`Lysbildeframvisning\n`);
				}
				if (formData.FacebookSamling) {
					facebookArray.push(`Samling\n`);
				}
				if (formData['FacebookGrafisk materiale']) {
					facebookArray.push(`Grafisk materiale\n`);
				}

				// Facebook Periods
				const facebookPeriods = formData.periods.facebook || [];
				if (facebookPeriods.length > 0) {
					facebookArray.push({
						text: '\nPerioder:',
						fontSize: 14,
						color: '#000000',
					});
					facebookPeriods.forEach((period, index) => {
						facebookArray.push(`Periode ${index + 1}:\n`);
						facebookArray.push(
							`Dato fra: ${formatDate(period.datefrom)}\n`
						);
						facebookArray.push(
							`Dato til: ${formatDate(period.dateto)}\n`
						);
						facebookArray.push(`Spending: ${period.spending}\n`);
						if (period.clientspending) {
							facebookArray.push(
								`Kunde står for mediaspending!\n`
							);
						}
						facebookArray.push(
							`Adm. gebyr eksterne kanaler: ${period.techFee}\n`
						);
						facebookArray.push(`\n`);
					});
				}
				eksterneArray.push(...facebookArray);
			}
			// End Facebook input PDF

			// LinkedIn
			if (formData.externalLinkedin) {
				const linkedinArray = [
					{
						text: '\nLinkedIn\n',
						fontSize: 16,
						color: '#000000',
					},
				];

				if (formData.LinkedinBildeannonsee) {
					linkedinArray.push(`Bildeannonse\n`);
				}
				if (formData.LinkedinVideoannonse) {
					linkedinArray.push(`Videoannonse\n`);
				}
				if (formData.LinkedinKarusell) {
					linkedinArray.push(`Karusell\n`);
				}
				if (formData['LinkedinIn-mail']) {
					linkedinArray.push(`In-mail\n`);
				}
				if (formData['LinkedinGrafisk materiale']) {
					linkedinArray.push(`Grafisk materiale\n`);
				}

				// LinkedIn Periods
				const linkedinPeriods = formData.periods.linkedin || [];
				if (linkedinPeriods.length > 0) {
					linkedinArray.push({
						text: '\nPerioder:',
						fontSize: 14,
						color: '#000000',
					});
					linkedinPeriods.forEach((period, index) => {
						linkedinArray.push(`Periode ${index + 1}:\n`);
						linkedinArray.push(
							`Dato fra: ${formatDate(period.datefrom)}\n`
						);
						linkedinArray.push(
							`Dato til: ${formatDate(period.dateto)}\n`
						);
						linkedinArray.push(`Spending: ${period.spending}\n`);
						if (period.clientspending) {
							linkedinArray.push(
								`Kunde står for mediaspending!\n`
							);
						}
						linkedinArray.push(
							`Adm. gebyr eksterne kanaler: ${period.techFee}\n`
						);
						linkedinArray.push(`\n`);
					});
				}
				eksterneArray.push(...linkedinArray);
			}

			// End LinkedIn

			// Snapchat
			if (formData.externalSnapchat) {
				const snapchatArray = [
					{
						text: '\nSnapchat\n',
						fontSize: 16,
						color: '#000000',
					},
				];

				if (formData.SnapchatBildeannonse) {
					snapchatArray.push(`Bildeannonse\n`);
				}
				if (formData.SnapchatVideoannonse) {
					snapchatArray.push(`Videoannonse\n`);
				}
				if (formData['SnapchatCollection ad']) {
					snapchatArray.push(`Collection ad\n`);
				}
				if (formData['SnapchatGeo-filter']) {
					snapchatArray.push(`Geo-filter\n`);
				}
				if (formData['SnapchatGrafisk materiale']) {
					snapchatArray.push(`Grafisk materiale\n`);
				}

				// Snapchat Periods
				const snapchatPeriods = formData.periods.snapchat || [];
				if (snapchatPeriods.length > 0) {
					snapchatArray.push({
						text: '\nPerioder:',
						fontSize: 14,
						color: '#000000',
					});
					snapchatPeriods.forEach((period, index) => {
						snapchatArray.push(`Periode ${index + 1}:\n`);
						snapchatArray.push(
							`Dato fra: ${formatDate(period.datefrom)}\n`
						);
						snapchatArray.push(
							`Dato til: ${formatDate(period.dateto)}\n`
						);
						snapchatArray.push(`Spending: ${period.spending}\n`);
						if (period.clientspending) {
							snapchatArray.push(
								`Kunde står for mediaspending!\n`
							);
						}
						snapchatArray.push(
							`Adm. gebyr eksterne kanaler: ${period.techFee}\n`
						);
						snapchatArray.push(`\n`);
					});
				}
				eksterneArray.push(...snapchatArray);

				contentArray.push(...eksterneArray);
			}

			// End Snapchat

			// Google
			if (formData.externalGoogle) {
				const googleArray = [
					{
						text: '\nGoogle\n',
						fontSize: 16,
						color: '#000000',
					},
				];

				if (formData['GoogleDisplay flatfil']) {
					googleArray.push(`Display flatfil\n`);
				}
				if (formData['GoogleDisplay video']) {
					googleArray.push(`Display video\n`);
				}
				if (formData.GoogleYoutube) {
					googleArray.push(`Youtube\n`);
				}
				if (formData['GoogleSøk']) {
					googleArray.push(`Søk\n`);
				}
				if (formData['GoogleGrafisk materiale']) {
					googleArray.push(`Grafisk materiale\n`);
				}

				// Google Periods
				const googlePeriods = formData.periods.google || [];
				if (googlePeriods.length > 0) {
					googleArray.push({
						text: '\nPerioder:',
						fontSize: 14,
						color: '#000000',
					});
					googlePeriods.forEach((period, index) => {
						googleArray.push(`Periode ${index + 1}:\n`);
						googleArray.push(
							`Dato fra: ${formatDate(period.datefrom)}\n`
						);
						googleArray.push(
							`Dato til: ${formatDate(period.dateto)}\n`
						);
						googleArray.push(`Spending: ${period.spending}\n`);
						if (period.clientspending) {
							googleArray.push(`Kunde står for mediaspending!\n`);
						}
						googleArray.push(
							`Adm. gebyr eksterne kanaler: ${period.techFee}\n`
						);
						googleArray.push(`\n`);
					});
				}
				eksterneArray.push(...googleArray);
			}

			// End Google

			// Rapport
			if (formData.externalRapport) {
				const rapportArray = [
					{
						text: '\nRapport\n',
						fontSize: 16,
						color: '#000000',
					},
				];

				if (formData.externalRapportKampanje) {
					rapportArray.push(`Kampanjerapport - 1600,-\n`);
				}
				if (formData.externalRapportInnsikt) {
					rapportArray.push(`Kampanjerapport med innsikt - 3000,-\n`);
				}
				if (formData.externalRapportUtenInnsikt) {
					rapportArray.push(
						`Kampanjerapport uten innsikt - 2500,-\n`
					);
				}
				eksterneArray.push(...rapportArray);
			}
			// End Rapport

			contentArray.push(...eksterneArray);
		}

		// Grafisk produksjon

		if (formData.grafiskProduksjon) {
			const graphicalArray = [
				{
					text: 'Grafisk Produksjon\n',
					fontSize: 18,
					color: '#005379',
					margin: [0, 20, 0, 0],
				},
			];

			// Displayannonse
			if (formData.graphDisplay) {
				const displayArray = [
					{
						text: '\nDisplayannonse\n\n',
						fontSize: 16,
						color: '#000000',
					},
				];

				let displayCPM = formData.displayvisninger
					? Math.round(formData.displayvisninger * 0.01)
					: 0;

				displayArray.push(`Type displayannonse:\n`);
				if (formData.displayjpg) {
					displayArray.push(`Display JPG\n`);
				}
				if (formData.displayslider) {
					displayArray.push(`Display Slider\n`);
				}
				if (formData.displaybevegelse) {
					displayArray.push(`Display med Bevegelse\n`);
				}
				// if (formData.displayadssets) {
				// 	displayArray.push(`Display Adssets ACM\n\n`);
				// }
				// if (formData.bannerflow) {
				// 	displayArray.push(`Bannerflow\n\n`);
				// }
				// if (formData.bannerflowvideo) {
				// 	displayArray.push(`Bannerflow video\n\n`);
				// }

				if (displayFormats) {
					displayArray.push(`\n\nFormater:\n`);
				}
				let formatNotes = '';
				for (const [key, value] of Object.entries(formData)) {
					if (key.startsWith('format')) {
						if (value) {
							// Find the corresponding display format object
							console.log(typeof displayFormats);
							console.log('info: ', displayFormats);
							const formatObj = displayFormats.find(
								(format) => format.name === key
							);
							if (formatObj) {
								formatNotes += `- ${formatObj.label}: ${formatObj.value}\n`;
							}
						}
					}
				}

				displayArray.push(formatNotes);

				if (formData.otherFormatDisplay) {
					displayArray.push(
						`\nAnnet format: ${formData.otherFormatDisplay}\n\n`
					);
				}

				displayArray.push(
					`\nDato fra: ${formatDate(formData.stilldatofra)}\n`
				);
				displayArray.push(
					`Dato til: ${formatDate(formData.stilldatotil)}\n`
				);
				if (formData.displayvisninger) {
					displayArray.push(
						`Antall visninger: ${formData.displayvisninger}\n`
					);
					displayArray.push(`CPM: ${displayCPM}\n`);
				}
				if (formData.displayrosenummer) {
					displayArray.push(
						`Rosenummer: ${formData.displayrosenummer}\n`
					);
				}
				if (formData.displayate) {
					displayArray.push(`ATE: ${formData.displayate}\n`);
				}

				graphicalArray.push(...displayArray);
			}
			// end displayannonse

			// Papirannonse
			if (formData.graphPaper) {
				const paperArray = [
					{
						text: '\nPapirannonse\n\n',
						fontSize: 16,
						color: '#000000',
					},
				];

				if (formData.graphpapertext) {
					paperArray.push(
						`Beskrivelse av bestilling: \n ${formData.graphpapertext}\n`
					);
				}

				graphicalArray.push(...paperArray);
			}
			// End papirannonse

			// Stillingskarusell
			if (formData.graphStillingskarusell) {
				const stillArray = [
					{
						text: '\nStillingskarusell\n\n',
						fontSize: 16,
						color: '#000000',
					},
				];

				if (formData.stilldatefrom) {
					stillArray.push(
						`Dato fra: ${formatDate(formData.stilldatefrom)}\n`
					);
				}
				if (formData.stilldateto) {
					stillArray.push(
						`Dato til: ${formatDate(formData.stilldateto)}\n`
					);
				}
				if (formData.onmn24) {
					stillArray.push(
						`Skal på mn24.no\n`
					);
				}

				graphicalArray.push(...stillArray);
			}
			// End stillingskarusell

			if (formData.stillingpremium) {
				const stillingPremiumArray = [
					{
						text: '\nStilling Premium valgt\n\n',
						fontSize: 14,
						color: '#000000',
					},
				];

				// stillingPremiumArray.push(`Stilling Premium er valgt\n`);
				graphicalArray.push(...stillingPremiumArray);
			}

			if (formData.ukensstilling) {
				const ukensstillingArray = [
					{
						text: '\nUkens stilling valgt\n\n',
						fontSize: 14,
						color: '#000000',
					},
				];

				// ukensstillingArray.push(`Ukens stilling er valgt\n`);
				graphicalArray.push(...ukensstillingArray);
			}

			if (formData.stillingrosenummer) {
				const stillingrosenummerArray = [
					{
						text: `\nRosenummer: ${formData.stillingrosenummer}\n`,
						fontSize: 14,
						color: '#000000',
					},
				];

				graphicalArray.push(...stillingrosenummerArray);
			}

			if (formData.graphOther) {
				const otherArray = [
					{
						text: '\nAnnet\n\n',
						fontSize: 16,
						color: '#000000',
					},
				];

				if (formData.graphothertext) {
					otherArray.push(
						`Beskrivelse av bestilling: \n ${formData.graphothertext}\n`
					);
				}

				graphicalArray.push(...otherArray);
			}

			contentArray.push(...graphicalArray);
		}

		// End Grafisk produksjon

		// Web
		if (formData.web) {
			const webArray = [
				{
					text: '\nWeb\n\n',
					fontSize: 16,
					color: '#000000',
				},
			];

			if (formData.webchoice) {
				webArray.push(`Webpakke: \n ${formData.webchoice}\n\n`);
			}

			if (formData.campChoice) {
				webArray.push(
					`Tillegg for kampanjeside: \n ${formData.campChoice}\n`
				);
			}
			if (formData.websiteChoice) {
				webArray.push(
					`Tillegg for hjemmeside: \n ${formData.websiteChoice}\n`
				);
			}

			if (formData.weblanding) {
				webArray.push(
					`\nLandingsside / ønsket domene: \n ${formData.weblanding}\n`
				);
			}
			if (formData.weblandingurl) {
				webArray.push(`Landingsside: ${formData.weblandingurl}\n`);
			}

			contentArray.push(...webArray);
		}
		// End web

		// Rapport
		if (formData.rapportAnalyse) {
			const rapportArray = [
				{
					text: '\nRapport og Analyse\n\n',
					fontSize: 16,
					color: '#000000',
				},
			];

			if (formData.analyticsga) {
				rapportArray.push(
					` - Opprette GA4 konto uten events sporing!\n`
				);
			}
			if (formData.rapdash) {
				rapportArray.push(
					`- Rapport | Dashboard - 12 måneders live dashboard med leads, trafikk- og eventsdata. Spesifikk events-tracking må avklares med Studio!\n`
				);
			}
			if (formData.rapcamptracking || formData.rapcampnotrack) {
				rapportArray.push(`\nTillegg for Rapport | Dashboard: \n`);
			}
			if (formData.rapcamptracking) {
				rapportArray.push(`Kampanjerapport med innsikt - 3000,-\n`);
			}
			if (formData.rapcampnotrack) {
				rapportArray.push(`Kampanjerapport uten innsikt - 2500,-\n`);
			}

			contentArray.push(...rapportArray);
		}

		// Bottom of the form
		// If korrNotNeeded is not selected
		if (!formData.korrNotNeeded) {
			contentArray.push(
				{
					text: 'BRIEF / MANUS',
					fontSize: 18,
					color: '#005379',
					margin: [0, 20, 0, 0],
				},
				{
					text: `Brief: ${formData.briefManus}`,
					margin: [0, 20, 0, 0],
				},
				{
					text: 'KORREKTUR',
					fontSize: 18,
					color: '#005379',
					margin: [0, 20, 0, 0],
				},
				`Korrekturdato: ${formatDate(formData.korr_date)}`,
				`Skal ha korrektur:\n ${formData.korrReceiver}`
			);
		}
		// If "NoKorrNeeded" is selected
		if (formData.korrNotNeeded) {
			contentArray.push(
				{
					text: 'BRIEF / MANUS',
					fontSize: 18,
					color: '#005379',
					margin: [0, 20, 0, 0],
				},
				{
					text: `Brief: ${formData.briefManus}`,
					margin: [0, 20, 0, 0],
				},
				{
					text: 'KORREKTUR',
					fontSize: 18,
					color: '#005379',
					margin: [0, 20, 0, 0],
				},
				`Trenger ikke korrektur`
			);
		}

		const svgImage = `<svg class="logobilde" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 212.7 71" style="enable-background:new 0 0 212.7 71;" xml:space="preserve">
        <g>
            <g>
                <path class="letter" fill="#ffffff" d="M91.6,52.3h-5.5v-8.9h5.3l0.7,4.1c0.8,0.3,1.8,0.5,3.5,0.5c2.9,0,4.4-1.2,4.4-3.4c0-2.7-2.8-3.1-6.4-4.1
                    c-4-1.1-7.1-3.2-7.1-8.6c0-6,4.2-8.9,10.3-8.9c2.3,0,3.8,0.2,4.8,0.4h4.9v8.1h-5.2l-0.7-3.5c-0.7-0.2-1.7-0.5-3.1-0.5
                    c-2.7,0-4.2,1-4.2,3.2c0,2.6,2.5,3.1,5.4,3.9c6,1.6,8.6,3.4,8.6,8.8c0,6.1-3.7,9.4-11,9.4C94.3,52.7,92.9,52.5,91.6,52.3z"></path>
                <path class="letter" fill="#ffffff" d="M111,46.6V34.9h-2.4v-4.4h2.4v-5.8h7.4v5.8h4.1v4.4h-4.1v10.6c0,2.2,0.7,2.6,2.9,2.6h1.2v4
                    c-1,0.3-2.3,0.6-4.2,0.6C113.8,52.7,111,51.3,111,46.6z"></path>
                <path class="letter" fill="#ffffff" d="M125.5,47.3V35.9c0-0.5-0.6-1-1-1h-1.2v-4.4h9.6v14.3c0,2.1,1,2.6,2.9,2.6c1,0,2.2-0.3,3.2-0.7V34.9h-2.6
                    v-4.4h10V47c0,0.4,0.5,1.1,1,1.1h1.2v4.2h-8.1c-0.7,0-1.2-0.6-1.4-2.8c-1.9,1.9-4.3,3.2-7.8,3.2C128.4,52.6,125.5,51.4,125.5,47.3
                    z"></path>
                <path class="letter" fill="#ffffff" d="M149.9,41.5c0-8.2,4.8-11.4,9.2-11.4c2.9,0,4.6,1.1,6.1,2.8v-5.3h-2.5v-4.4h8.7c0.8,0,1.2,0.6,1.2,1.6V47
                    c0,0.4,0.6,1.1,1,1.1h1.3v4.2h-8.3c-0.6,0-1-0.9-1.2-2.2c-1.3,1.5-3.5,2.6-6.3,2.6C154.2,52.7,149.9,49.8,149.9,41.5z M165.2,46.9
                    v-11c-0.9-0.3-2-0.7-3.1-0.7c-2.5,0-4.6,1.3-4.6,6.3c0,5.3,2,6.7,4.2,6.7C163.1,48.2,164.2,47.6,165.2,46.9z"></path>
                <path class="letter" fill="#ffffff" d="M176,48.1h1.4c0.5,0,1-0.6,1-1V36c0-0.4-0.5-1.1-1-1.1h-1.5v-4.4h8.8c0.7,0,1.1,0.7,1.1,2.2v14.4
                    c0,0.5,0.5,1,1,1h1.3v4.2H176V48.1z M178.3,25.6c0-2,1.6-3.5,3.7-3.5c2.1,0,3.7,1.5,3.7,3.5s-1.6,3.5-3.7,3.5
                    C179.9,29.1,178.3,27.6,178.3,25.6z"></path>
                <path class="letter" fill="#ffffff" d="M189.1,41.5c0-7,5.1-11.4,11.9-11.4c6.7,0,11.8,4.4,11.8,11.4c0,7-5.1,11.1-11.8,11.1
                    C194.2,52.6,189.1,48.5,189.1,41.5z M205.2,41.5c0-4.5-1.8-6.6-4.3-6.6c-2.5,0-4.4,2.1-4.4,6.6c0,4.5,1.8,6.6,4.4,6.6
                    C203.4,48.1,205.2,46,205.2,41.5z"></path>
            </g>
            <circle class="circle-outside" fill="#ffffff" cx="35.5" cy="35.5" r="35.5"></circle>
            <path class="circle-inside" fill="#004d7a" d="M49.2,19.6c1-1.2,2.7,0.9,3.1-0.8c0,0,0-0.1,0-0.1c-2.2-1.7-5.5-0.8-7.5,0.6c-0.7,0.5-1.6,1.3-2.1,1.9
                c-0.1,0-0.1,0-0.1,0c-0.6-1.1-1.5-2-2.4-2.9c-0.3,0-0.6,0.3-0.8,0.5c0.5,1.1,1.3,2.2,1.7,3.4c0.1,0.3,0.1,0.6,0.1,1c0,0,0,0,0,0.1
                L41.2,45c-2.1,1.9-4.5,3.7-6.8,5.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0l0,0l0,0c-1.4-1.1-3-2-4-3.3c2.8-2.3,5.2-4.8,6.6-7.8
                c2.5-5.5,2.5-13.8-2.2-18.4c-2.6-2.1-6.4-3.4-9.7-1.8c-3.2,1.4-5.1,4.7-5.5,8c-0.4,4.9,4,7.8,6.2,11.6c0.1,0.1,0.1,0.3,0.2,0.5
                c0,0.3,0,0.5,0,0.8c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.1-0.2,0.3-0.3,0.4
                c-0.1,0.1-0.3,0.3-0.4,0.4c-0.4,0.2-0.7,0.3-1,0.4c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0
                c-1.4-0.5-1.1-2.2-1.4-3.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.4-0.1-0.7,0-1,0.1c0,0-0.1,0.1-0.1,0.1c-0.3,0.6-0.3,1.4-0.2,2.1
                c0.3,1.1,0.6,1.7,1.4,2.3c0.3,0.2,0.7,0.4,1,0.4c1.2,0.3,2.1-0.1,3.1-0.6c2.3-1.6,3.3-4,3.5-6.6c0.3-5-4.5-7.9-6.4-12
                c0-0.1-0.1-0.2-0.1-0.3c-0.3-1.3-0.1-2.6,0.9-3.5c0.1-0.1,0.2-0.2,0.4-0.3c0.8-0.4,1.5-0.6,2.6-0.5c0.1,0,0.2,0,0.3,0.1
                c0.7,0.2,1.4,0.6,2.1,1.6c0.3,0.4,0.5,0.8,0.7,1.4c0.1,0.2,0.2,0.5,0.2,0.9c1.2,4.7,1.2,9.9,1.2,15c0.3,4-2.7,7.6-6.1,9.4
                c0,0,0,0,0,0c-0.3,0.2-1.2,0.7-1.5,0.8c-0.3,0.2-0.6,0.4-0.6,0.9c2,2,4.5,3.6,6.8,5.3l0,0c0.1,0.1,0.3,0.1,0.4,0.1c0,0,0.1,0,0.1,0
                l10.6-7.7c0.1,0.9,0,1.7,0.1,2.6c0,0.5,0.2,1.1,0.3,1.6c0.6,1.6,1.7,3.2,3.4,3.6c0.1,0,0.1,0,0.1,0c1.7-1.5,3.7-2.9,5.4-4.7
                c0.2-0.3,0.2-0.6,0-0.9C50,49.1,49.4,50,48.8,50c-0.7-0.4-1.1-1.1-1.2-1.8v-22C47.6,23.7,47.6,21.3,49.2,19.6z"></path>
        </g>
    </svg>`;

		const documentDefinition = {
			header: {
				table: {
					widths: ['auto', '*'], // 'auto' for the SVG, '*' for the remaining space
					body: [
						[
							{
								// SVG cell
								svg: svgImage,
								// You can use alignment to position your SVG left/right/center
								alignment: 'left',
								fit: [100, 100],
								// Adjust margin as needed [left, top, right, bottom]
								margin: [20, 20, 0, 20],
								// Set the fillColor for SVG background if needed
								fillColor: '#005379',
							},
							{
								// Text cell
								text: '',
								fillColor: '#005379',
								// Use margin to increase effective height of the header
								margin: [10, 30, 10, 30], // Adjust top and bottom margin to set the height
								// Apply text styles as needed
								style: 'headerText',
							},
						],
					],
				},
				layout: 'noBorders',
			},
			content: contentArray,
			styles: {
				headerText: {
					bold: true,
					fontSize: 16,
					color: 'white',
					// More styles can be applied as needed
				},
				// ... other styles
			},
			// Adjust the page Margins
			pageMargins: [40, 100, 40, 40],
		};

		// pdfMake.createPdf(documentDefinition).download();
		pdfMake.createPdf(documentDefinition).getBase64((data) => {
			console.log('Base64 Data:', data);
			resolve(data);
			// callback(data);
		});
	});
};
