import React, { useContext, useEffect, useState } from 'react';
import '../assets/scss/salesFormContainer.scss';
import ClientSection from '../components/sections/ClientSection';
import SalesPerson from '../components/sections/SalesPerson';
import WhatOrder from '../components/sections/WhatOrder';

// Authenticate
import UserContext from '../services/UserContext';
import withAuth from '../services/withAuth';

import '../assets/scss/inputField.scss';
import GenerellOrders from '../components/GenerellOrders';
import FormBottom from '../components/sections/FormBottom';

import Innholdsproduksjon from '../components/sections/Innholdsproduksjon';
import LandingPage from '../components/sections/LandingPage';
import External from '../components/sections/external';
import Graphical from '../components/sections/graphical';
import Rapanalytics from '../components/sections/rapanalytics';
import WebSection from '../components/sections/webSection';
// import Scraped from '../components/sections/Scraped';

import Snowfall from '../utility/Snowfall';

// PDF generator
import { generatePDF } from '../utility/pdfGenerator';

const FormContainer = ({ handlePriceChange }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [selectedSizes, setSelectedSizes] = useState({});
	const [scrapedServices, setScrapedServices] = useState({});
	const [errorMessage, setErrorMessage] = useState('');
	const [showModal, setShowModal] = useState(false);

	const { user } = useContext(UserContext);

	const displayFormats = [
		{
			label: 'Desktop - Netboard',
			value: '580x500 px',
			name: 'formatNetboard',
			id: '1',
		},
		{
			label: 'Mobil - Board',
			value: '320x250 px',
			name: 'formatBoard',
			id: '2',
		},
		{
			label: 'Desktop - Hestesko',
			value: '180x700 px, 1010x150 px, 180x700 px',
			name: 'formatHestesko',
			id: '3',
		},
		{
			label: 'Desktop - Topboard',
			value: '980x300 px',
			name: 'formatTopboard',
			id: '4',
		},
		{
			label: 'Mobile - Board XL',
			value: '320x400 px',
			name: 'formatBoardxl',
			id: '5',
		},
		{
			label: 'Mobil - Board XXL',
			value: '320x480 px',
			name: 'formatBoardxxl',
			id: '6',
		},
		{
			label: 'Desktop - Topboard XL',
			value: '980x500 px',
			name: 'formatTopboardxl',
			id: '7',
		},
		{
			label: 'Desktop - Skyscraper',
			value: '180x500 px',
			name: 'formatSkyscraper',
			id: '8',
		},
		{
			label: 'Desktop - Wallpaper',
			value: '1920x1300 px',
			name: 'formatWallpaper',
			id: '9',
		},
		{
			label: 'Desktop - Brandboard',
			value: '980x300 px',
			name: 'formatBrandboard',
			id: '10',
		},
		{
			label: 'Mobil - Superboard',
			value: '320x640 px',
			name: 'formatMobileSuperboard',
			id: '11',
		},
		{
			label: 'Desktop - Fullscreen',
			value: '1920x1080 px, safe-zone 1400x940 px',
			name: 'formatDfullscreen',
			id: '12',
		},
		{
			label: 'Desktop - Brandboard XL',
			value: '980x600 px',
			name: 'formatBrandboardxl',
			id: '13',
		},
		{
			label: 'Mobil - Fullscreen',
			value: '1920x1080 px, safe-zone 528x940 px',
			name: 'formatMfullscreen',
			id: '14',
		},
	];

	// Fetch the scraped data when the component mounts
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api/scrape-prices`)
			.then((response) => response.json())
			.then((data) => {
				const servicesObject = {};
				data.prices.services.forEach((service) => {
					servicesObject[service.name] = service.price;
				});
				setScrapedServices(servicesObject);
			})
			.catch((error) => {
				console.error('Error fetching scraped data:', error);
			});
	}, []);

	const [formData, setFormData] = useState({
		firstName: user.first_name,
		lastName: user.last_name,
		email: user.email,
		orderNumber: [],
		sections: [],
		innholdsproduksjon: false,
		contentrosenummer: [''],
		displayrosenummer: [''],
		landingpages: [''],
		typeOfOrder: '',
		periods: {
			facebook: [
				{
					datefrom: '',
					dateto: '',
					spending: '',
					techFee: 0,
					clientspending: false,
				},
			],
			linkedin: [
				{
					datefrom: '',
					dateto: '',
					spending: '',
					techFee: 0,
					clientspending: false,
				},
			],
			snapchat: [
				{
					datefrom: '',
					dateto: '',
					spending: '',
					techFee: 0,
					clientspending: false,
				},
			],
			google: [
				{
					datefrom: '',
					dateto: '',
					spending: '',
					techFee: 0,
					clientspending: false,
				},
			],
		},
		selectedContentPackage: null,
		selectedAdditionalServices: [],
	});

	const addPeriod = (platform) => {
		setFormData((prevData) => ({
			...prevData,
			periods: {
				...prevData.periods,
				[platform]: [
					...prevData.periods[platform],
					{
						datefrom: '',
						dateto: '',
						spending: 0,
						techFee: 0,
						clientSpending: false,
					},
				],
			},
		}));
	};

	const removeLastPeriod = (platform) => {
		setFormData((prevData) => {
			const updatedPeriods = [...prevData.periods[platform]];
			if (updatedPeriods.length > 1) {
				// Prevent removing all periods
				updatedPeriods.pop();
			}
			return {
				...prevData,
				periods: {
					...prevData.periods,
					[platform]: updatedPeriods,
				},
			};
		});
	};

	useEffect(() => {}, [formData]);

	const handleChange = (e, index, platform) => {
		const { name, value, type, checked } = e.target;
		const updateValue =
			type === 'checkbox'
				? checked
					? e.target.value
					: ''
				: e.target.value;
		// console.log(e.target.value);

		if (name === 'korr_receiver') {
			setFormData((prevData) => ({
				...prevData,
				korrReceiver: updateValue,
			}));
		}

		if (name === 'orderNumber') {
			// Split the input value by commas to get an array of order numbers
			const orderNumbers = value.split(',').map((item) => item.trim());
			setFormData((prevData) => ({
				...prevData,
				orderNumber: orderNumbers,
			}));
		}

		// Handle the "korr_same_seller" checkbox
		if (name === 'korr_same_seller') {
			if (checked) {
				// If the checkbox is checked, update korrReceiver with formData.email
				setFormData((prevData) => ({
					...prevData,
					korrReceiver: formData.email,
					korr_same_seller: true,
				}));
			} else {
				// If the checkbox is unchecked, clear the korrReceiver value
				setFormData((prevData) => ({
					...prevData,
					korrReceiver: '',
					korr_same_seller: false,
				}));
			}
		} else if (type === 'radio') {
			setFormData((prevData) => {
				// If the radio button is already selected, unselect it
				if (prevData[name] === value) {
					return { ...prevData, [name]: '' };
				}
				// Otherwise, select the new radio button
				return { ...prevData, [name]: value };
			});
		} else if (
			name.startsWith('face') ||
			name.startsWith('linkedin') ||
			name.startsWith('snapchat') ||
			name.startsWith('google')
		) {
			if (typeof index !== 'undefined') {
				setFormData((prevData) => {
					const updatedPeriods = [...prevData.periods[platform]];
					if (updatedPeriods[index]) {
						const actualName = name
							.replace(`${platform}`, '')
							.toLowerCase();
						updatedPeriods[index][actualName] =
							type === 'number' && updateValue !== ''
								? parseFloat(updateValue)
								: updateValue;

						// Update techFee if spending is updated
						if (actualName === 'spending') {
							updatedPeriods[index]['techFee'] =
								updatedPeriods[index]['spending'] * 0.1;
						}
					}
					return {
						...prevData,
						periods: {
							...prevData.periods,
							[platform]: updatedPeriods,
						},
					};
				});
			} else {
				setFormData((prevData) => ({
					...prevData,
					[name]: updateValue,
				}));
			}
		} else {
			setFormData((prevData) => ({
				...prevData,
				[name]: updateValue,
			}));
		}
	};

	const handleCheckboxChange = (e) => {
		const { name, checked } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: checked,
		}));
	};

	const generatePDFAsync = async () => {
		try {
			console.log('About to generate PDF');
			const result = await generatePDF(formData, displayFormats);
			console.log('PDF generated successfully', result);
			return result;
		} catch (err) {
			console.log('Error generating PDF:', err);
			throw err;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);
		let errorMessage = '';

		console.log('handleSubmit started');
		const formDataWithFormats = {
			...formData,
			displayFormats,
		};

		try {
			// Retrieve userId from localStorage
			const userId = localStorage.getItem('userId');
			// console.log("userId retrieved:", userId);

			console.log('Generating PDF...');

			// Generate PDF
			const base64String = await generatePDFAsync();
			// console.log("PDF generated:", base64String);
			// if (!base64String) throw new Error('Failed to generate PDF');

			console.log('Proceeding to save the PDF...');
			// Save the PDF
			const saveResponse = await fetch(
				`${process.env.REACT_APP_API_URL}/api/pdfs/save-pdf`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						pdfData: base64String,
						clientName: formData.clientName,
						orderNumber: formData.orderNumber,
						typeOfOrder: formData.typeOfOrder,
					}),
				}
			);
			if (!saveResponse.ok) throw new Error('Failed to save PDF');

			console.log('PDF saved successfully');

			// Send email
			const emailResponse = await fetch(
				`${process.env.REACT_APP_API_URL}/api/email/send-email`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						pdfBase64: base64String,
						email: formData.email,
						orderNumber: formData.orderNumber,
						clientName: formData.clientName,
						typeOfOrder: formData.typeOfOrder,
					}),
				}
			);
			if (!emailResponse.ok) throw new Error('Failed to send email');

			// Create Asana task
			const asanaResponse = await fetch(
				`${process.env.REACT_APP_API_URL}/api/asana/form-sent`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						...formData,
						displayFormats,
					}),
				}
			);
			if (!asanaResponse.ok)
				throw new Error('Failed to create Asana task');

			// Save form data
			const formDataResponse = await fetch(
				`${process.env.REACT_APP_API_URL}/api/save-form`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						...formData,
						userId,
						displayFormats,
					}),
				}
			);
			if (!formDataResponse.ok) throw new Error('Error saving form data');

			setIsSubmitted(true);
			setShowModal(true);
		} catch (error) {
			console.error(error);
			// Check if error.message exists; otherwise, use a default message or convert error to string
			errorMessage = error.message
				? `Error in ${error.message}`
				: `Error: ${error.toString()}`;
			setErrorMessage(errorMessage);
		} finally {
			setIsSubmitting(false);
			if (errorMessage) {
				console.log(errorMessage);
			}
		}
	};

	const closeModal = () => {
		setShowModal(false);
	};

	const resetForm = () => {
		window.location.reload();
	};

	return (
		<>
			{/* <div className='announcement'>
				<div className='announcement__text'>
					<h1>Godt nytt år</h1>
				</div>
			</div> */}

			<form className='salesFormContainer' onSubmit={handleSubmit}>
				<SalesPerson formData={formData} handleChange={handleChange} />
				<ClientSection
					formData={formData}
					handleChange={handleChange}
					// orderNumberInput={orderNumberInput}
				/>
				<LandingPage
					formData={formData}
					setFormData={setFormData}
					handleChange={handleChange}
				/>

				<WhatOrder handleChange={handleChange} formData={formData} />

				{formData.typeOfOrder && (
					<>
						{/* Generelle ordrer */}
						<GenerellOrders
							formData={formData}
							handleChange={handleChange}
						/>

						{/* Innholdsproduksjon */}
						{formData.innholdsproduksjon && (
							<Innholdsproduksjon
								formData={formData}
								setFormData={setFormData}
								handleChange={handleChange}
								handlePriceChange={handlePriceChange}
							/>
						)}

						{formData.eksterneKanaler && (
							<External
								formData={formData}
								handleChange={handleChange}
								addPeriod={addPeriod}
								removeLastPeriod={removeLastPeriod}
								services={scrapedServices}
								handlePriceChange={handlePriceChange}
							/>
						)}

						{formData.grafiskProduksjon && (
							<Graphical
								formData={formData}
								handleChange={handleChange}
								handleCheckboxChange={handleCheckboxChange}
								displayFormats={displayFormats}
								selectedSizes={selectedSizes}
								services={scrapedServices}
								handlePriceChange={handlePriceChange}
								setFormData={setFormData}
							/>
						)}

						{formData.web && (
							<WebSection
								formData={formData}
								handleChange={handleChange}
								handlePriceChange={handlePriceChange}
							/>
						)}

						{formData.rapportAnalyse && (
							<Rapanalytics
								formData={formData}
								handleChange={handleChange}
								handlePriceChange={handlePriceChange}
							/>
						)}

						{/* Bottom of the form starts here with Brief / manus */}
						<FormBottom
							formData={formData}
							handleChange={handleChange}
						/>

						<button
							className={`submit-btn ${
								isSubmitting ? 'submitting' : ''
							}`}
							type='submit'
							disabled={isSubmitting}
						>
							{isSubmitting ? 'Sender...' : 'Send'}
						</button>
						{isSubmitted && !errorMessage && (
							<p>Skjema har blitt sendt</p>
						)}
						{errorMessage && (
							<p className='error-message'>
								Noe gikk galt: {errorMessage}
							</p>
						)}
					</>
				)}
			</form>

			{showModal && (
				<div className='modal'>
					<div className='modal-content'>
						<h2>Skjema har blitt sendt</h2>
						<div className='buttons'>
							<button onClick={closeModal}>
								Tilbake til skjema
							</button>
							<button onClick={resetForm}>Tøm alle felter</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const FormContainerWithAuth = withAuth(FormContainer);

export default FormContainerWithAuth;
